/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .pConclusion {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #858591;
  }
  .wrapRowConclusion {
    margin: 271px 0px 158px 0px;
  }
  .wrapResults2 {
    width: 466px;
    float: right;
    margin: 60px 0px 0px 0px;
  }
  .wrapImgResult2Top {
    display: block;
  }
  .wrapImgResult2Bottom {
    display: none;
  }
  .pResults1 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #858591;
  }
  .imgResult2 {
    width: 100%;
    margin: 0px auto 0px auto;
  }
  .wrapRowResults2Block {
    margin: 179px 0px 271px 0px;
  }
  .wrapRowResults1Block {
    margin: 122px 0px 179px 0px;
  }
  .imgResult1 {
    width: 100%;
    display: block;
    margin: 0px auto 0 auto;
  }
  .wrapRowDevelopmentProcessBlock {
    margin: 156px 0px 153px 0px;
  }
  .wrapRowResultsText {
    margin: 153px 0px 122px 0px;
  }
  .imgNumber {
    width: 60px;
    width: 60px;
    position: absolute;
    top: 78px;
    left: -30px;
  }
  .itemImgNumber {
    position: relative;
    margin: 3%;
    padding: 4%;
    width: 26%;
    height: 211px;
    float: left;
    background: #ffffff;
    box-shadow: 10px 10px 100px rgba(229, 229, 229, 0.5),
    0px 0px 24px rgba(122, 121, 156, 0.06);
    border-radius: 10px;
  }
  .itemImgNumber1 p.pDevelopmentProcess {
    margin: 16px 0px 0px 0px;
  }
  .itemImgNumber2 p.pDevelopmentProcess {
    margin: 5px 0px 0px 0px;
  }
  .itemImgNumber3 p.pDevelopmentProcess {
    margin: 40px 0px 0px 0px;
  }
  .itemImgNumber4 p.pDevelopmentProcess {
    margin: 40px 0px 0px 0px;
  }
  .itemImgNumber5 p.pDevelopmentProcess {
    margin: 40px 0px 0px 0px;
  }
  .itemImgNumber.itemImgNumber4 {
    margin: 3% 3% 3% 18%;
  }
  .itemImgNumber.itemImgNumber5 {
    margin: 3% 18% 3% 3%;
  }

  .wrapRowDevelopmentProcessText {
    margin: 200px 0px 156px 0px;
  }
  .h2DevelopmentProcess {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #06076a;
  }
  .wrapRowCustomerProblems2 {
    margin: 200px 0px 200px 0px;
  }
  .imgCustomerProblems2top {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .imgCustomerProblems2bottom {
    display: none;
  }
  // .h2CustomerProblems {
  //   font-style: normal;
  //   font-weight: bold;
  //   font-size: 48px;
  //   line-height: 120%;
  //   text-align: center;
  //   text-transform: uppercase;
  //   color: #06076a;
  //   width: 99%;
  // }
  .pCustomerProblems {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #858591;
    width: 99%;
  }
  .ulCustomerProblems li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #858591;
    width: 99%;
    list-style-type: none;
  }
  .ulCustomerProblems li:before {
    content: " - ";
  }
  .imgCustomerProblems {
    width: 100%;
    display: block;
    margin: 58px auto 0px auto;
  }
  .wrapRowCustomerProblems {
    margin: 200px 0px 200px 0px;
  }
  .wrapTechStacks {
    width: 16%;
    float: left;
    background: #ffffff;
    box-shadow: 10px 10px 100px rgba(229, 229, 229, 0.5),
    0px 0px 24px rgba(122, 121, 156, 0.06);
    border-radius: 10px;
    margin: 2%;
  }
  .wrapTechStacks img {
    width: 62%;
    display: block;
    margin: 7% auto;
  }
  .wrapRowTechStacksText {
    margin: 200px 0px 80px 0px;
  }
  .h2TechStacksText {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #06076a;
  }
  .wrapRowChatBotsText {
    margin: 140px 0px 200px 0px;
  }
  .h2ChatBotsTextBlue {
    color: #65a5ff;
  }
  .h2ChatBotsText {
    font-style: normal;
    font-weight: bold;
    font-size: 76px;
    line-height: 100%;
    color: #06076a;
    margin: 40px 0px 15px 0px;
  }
  .pChatBotsText {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: #858591;
    margin: 15px 0px 30px 0px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 2500px) {
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .imgConclusion {
    width: 439px;
    height: 394px;
    margin: 0px 0px 0px 16px;
  }
  .wrapRowConclusion {
    margin: 181px 0px 175px 0px;
  }
  .wrapResults2 {
    width: 457px;
  }
  .imgResult2 {
    margin: 52px auto 0px auto;
  }
  .wrapRowResults2Block {
    margin: 116px 0px 181px 0px;
  }
  .imgResult1 {
    margin: 0px auto 0 auto;
  }
  .wrapRowResults1Block {
    margin: 122px 0px 116px 0px;
  }
  .wrapRowDevelopmentProcessBlock {
    margin: 115px 0px 179px 0px;
  }
  .wrapRowResultsText {
    margin: 179px 0px 122px 0px;
  }
  .wrapRowDevelopmentProcessText {
    margin: 200px 0px 115px 0px;
  }
  .wrapRowCustomerProblems2 {
    margin: 184px 0px 200px 0px;
  }
  .imgCustomerProblems2top {
    width: 432px;
    height: 298px;
  }
  .wrapRowCustomerProblems {
    margin: 128px 0px 184px 0px;
  }
  .wrapRowTechStacksText {
    margin: 316px 0px 80px 0px;
  }
  .wrapRowChatBotsText {
    margin: 220px 0px 316px 0px;
  }
  .h2ChatBotsText {
    margin: 0px 0px 15px 0px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .imgConclusion {
    margin: 77px auto 0px auto;
  }
  .wrapRowConclusion {
    margin: 40px 0px 146px 0px;
  }
  .wrapResults2 {
    width: 100%;
    float: none;
  }
  .wrapImgResult2Top {
    display: none;
  }
  .wrapImgResult2Bottom {
    display: block;
  }
  .imgResult2 {
    margin: 0 auto;
  }
  .wrapRowResults2Block {
    margin: 40px 0px 40px 0px;
  }
  .wrapRowResults1Block {
    margin: 40px 0px 40px 0px;
  }
  .imgResult1 {
    margin: 0px auto;
  }
  .wrapRowDevelopmentProcessBlock {
    margin: 167px 0px 126px 0px;
  }
  .wrapRowResultsText {
    margin: 126px 0px 40px 0px;
  }
  .itemImgNumber1 p.pDevelopmentProcess {
    margin: 33px 0px 0px 13px;
  }
  .itemImgNumber2 p.pDevelopmentProcess {
    margin: 24px 0px 0px 13px;
  }
  .itemImgNumber3 p.pDevelopmentProcess {
    margin: 55px 0px 0px 13px;
  }
  .itemImgNumber4 p.pDevelopmentProcess {
    margin: 55px 0px 0px 13px;
  }
  .itemImgNumber5 p.pDevelopmentProcess {
    margin: 55px 0px 0px 13px;
  }
  .itemImgNumber {
    margin: 5%;
    padding: 4%;
    width: 39%;
  }
  .itemImgNumber.itemImgNumber4 {
    margin: 5% 5% 5% 5%;
  }
  .itemImgNumber.itemImgNumber5 {
    margin: 5% 30% 5% 30%;
  }
  .wrapRowDevelopmentProcessText {
    margin: 126px 0px 102px 0px;
  }
  .wrapRowCustomerProblems2 {
    margin: 78px 0px 126px 0px;
  }
  .imgCustomerProblems2top {
    display: none;
  }
  .imgCustomerProblems2bottom {
    display: block;
    width: 100%;
    margin: 0px auto 0px auto;
  }
  .imgCustomerProblems {
    margin: 35px auto 0px auto;
  }
  .wrapRowCustomerProblems {
    margin: 130px 0px 78px 0px;
  }
  .wrapTechStacks {
    width: 46%;
    margin: 2%;
  }
  .wrapTechStacks img {
    width: 62%;
    display: block;
    margin: 7% auto;
  }
  .wrapTechStacks.wrapTechStacks5 {
    margin: 2% 25%;
  }
  .wrapRowTechStacksText {
    margin: 148px 0px 80px 0px;
  }
  .wrapRowChatBotsText {
    margin: 220px 0px 150px 0px;
  }
  .h2ChatBotsText {
    text-align: center;
  }
  .pChatBotsText {
    text-align: center;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .imgConclusion {
    margin: 67px auto 0px auto;
  }
  .wrapRowConclusion {
    margin: 91px 0px 115px 0px;
  }
  .wrapRowResults2Block {
    margin: 35px 0px 91px 0px;
  }
  .wrapRowResults1Block {
    margin: 44px 0px 35px 0px;
  }
  .wrapRowDevelopmentProcessBlock {
    margin: 129px 0px 100px 0px;
  }
  .wrapRowResultsText {
    margin: 100px 0px 44px 0px;
  }
  .itemImgNumber1 p.pDevelopmentProcess {
    margin: 53px 20px 0px 34px;
  }
  .itemImgNumber2 p.pDevelopmentProcess {
    margin: 53px 20px 0px 34px;
  }
  .itemImgNumber3 p.pDevelopmentProcess {
    margin: 61px 20px 0px 34px;
  }
  .itemImgNumber4 p.pDevelopmentProcess {
    margin: 75px 20px 0px 34px;
  }
  .itemImgNumber5 p.pDevelopmentProcess {
    margin: 65px 20px 0px 34px;
  }
  .itemImgNumber {
    width: 91%;
  }
  .itemImgNumber.itemImgNumber5 {
    margin: 5% 5% 5% 5%;
  }
  .wrapRowDevelopmentProcessText {
    margin: 100px 0px 71px 0px;
  }
  .wrapRowCustomerProblems2 {
    margin: 56px 0px 100px 0px;
  }
  .imgCustomerProblems2bottom {
    margin: 51px auto 0px auto;
  }
  .imgCustomerProblems {
    margin: 35px auto 0px auto;
  }
  .wrapRowCustomerProblems {
    margin: 138px 0px 56px 0px;
  }
  .wrapRowTechStacksText {
    margin: 136px 0px 50px 0px;
  }
  .wrapRowChatBotsText {
    margin: 220px 0px 136px 0px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
  .imgConclusion {
    margin: 61px auto 0px auto;
  }
  .wrapRowConclusion {
    margin: 84px 0px 57px 0px;
  }
  .wrapRowResults2Block {
    margin: 29px 0px 84px 0px;
  }
  .wrapRowResults1Block {
    margin: 15px 0px 29px 0px;
  }
  .wrapRowDevelopmentProcessBlock {
    margin: 48px 0px 59px 0px;
  }
  .wrapRowResultsText {
    margin: 59px 0px 15px 0px;
  }
  .itemImgNumber1 p.pDevelopmentProcess {
    margin: 35px 20px 0px 34px;
  }
  .itemImgNumber2 p.pDevelopmentProcess {
    margin: 40px 20px 0px 34px;
  }
  .itemImgNumber4 p.pDevelopmentProcess {
    margin: 65px 20px 0px 34px;
  }
  .itemImgNumber5 p.pDevelopmentProcess {
    margin: 64px 20px 0px 34px;
  }
  .wrapRowDevelopmentProcessText {
    margin: 48px 0px 48px 0px;
  }
  .h2DevelopmentProcess {
    font-size: 36px;
  }
  .wrapRowCustomerProblems2 {
    margin: 36px 0px 48px 0px;
  }
  .imgCustomerProblems2bottom {
    width: 100%;
    margin: 40px auto 0px auto;
  }
  .wrapRowCustomerProblems {
    margin: 90px 0px 36px 0px;
  }
  .wrapTechStacks {
    width: 96%;
  }
  .wrapTechStacks.wrapTechStacks5 {
    margin: 2%;
  }
  .h2TechStacksText {
    font-size: 36px;
  }
  .wrapRowTechStacksText {
    margin: 106px 0px 44px 0px;
  }
  .wrapRowChatBotsText {
    margin: 220px 0px 106px 0px;
  }
  .h2ChatBotsText {
    font-size: 50px;
    margin: 0px 0px 28px 0px;
  }
  .pChatBotsText {
    margin: 28px 0px 28px 0px;
  }
}
