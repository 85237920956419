/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .wrapRowOnlineStore1 {
    margin: 70px 0px 180px 0px;
  }
  .h2OnlineStore {
    font-style: normal;
    font-weight: bold;
    font-size: 76px;
    line-height: 100%;
    color: #06076a;
  }
  .h2OnlineStore span {
    color: #65a5ff;
  }
  .pOnlineStore {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: #858591;
  }
  .pOnlineStore1 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #858591;
  }
  .h2OnlineStore2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    text-align: left;
    text-transform: uppercase;
    color: #06076a;
  }
  .buttonProduct a {
    color: #ffffff;
  }
  .h2OnlineStore1 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #06076a;
  }
  .wrapRowOnlineStore2 {
    margin: 180px 0px 80px 0px;
  }
  .wrapRowOnlineStore3 {
    margin: 80px 0px 200px 0px;
  }
  .wrapRowOnlineStore4 {
    margin: 200px 0px 150px 0px;
  }
  .colImgCustomerProblems3Top {
    display: block;
  }
  .colImgCustomerProblems3Bottom {
    display: none;
  }
  .imgCustomerProblems1 {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .imgCustomerProblems3 {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .wrapRowOnlineStore5 {
    margin: 150px 0px 190px 0px;
  }
  .wrapRowOnlineStore6 {
    margin: 190px 0px 150px 0px;
  }
  .wrapRowOnlineStore7 {
    margin: 150px 0px 60px 0px;
  }
  .wrapRowOnlineStore8 {
    margin: 60px 0px 45px 0px;
  }
  .wrapRowOnlineStore8 p {
    margin: 240px 0px 0px 0px;
  }
  .wrapRowOnlineStore9 {
    margin: 45px 0px 55px 0px;
  }
  .wrapRowOnlineStore9 p {
    margin: 170px 0px 0px 0px;
  }
  .wrapRowOnlineStore10 {
    margin: 55px 0px 180px 0px;
  }
  .wrapRowOnlineStore11 {
    margin: 180px 0px 175px 0px;
  }
  .wrapRowOnlineStore10 p {
    margin: 190px 0px 0px 0px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 2500px) {
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .wrapRowConclusion.row .btn.buttonOne {
    margin: 35px auto 77px auto;
    display: block;
  }
  .h2APISalesTool,
  .h2APISalesTool2,
  .h2Conclusion,
  .h2OnlineStore,
  .h2OnlineStore2{
    text-align: center;
  }
  .colImgCustomerProblems3Top {
    display: none;
  }
  .colImgCustomerProblems3Bottom {
    display: block;
  }
  .wrapRowOnlineStore8 p {
    margin: 0px 0px 0px 0px;
  }
  .wrapRowOnlineStore9 p {
    margin: 0px 0px 0px 0px;
  }
  .wrapRowOnlineStore10 p {
    margin: 0px 0px 0px 0px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .wrapRowConclusion.row .btn.buttonOne {
    margin: 35px auto 77px auto;
    display: block;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .wrapRowConclusion.row .btn.buttonOne {
    margin: 35px auto 0px auto;
    display: block;
  }
  .h2OnlineStore1 {
    font-size: 42px;
  }
  .h2Conclusion,
  .h2OnlineStore2{
    font-size: 38px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
  .wrapRowConclusion.row .btn.buttonOne {
    margin: 40px auto 0px auto;
    display: block;
  }
  .h2OnlineStore1 {
    font-size: 32px;
  }
}
