/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .buttonOne a {
    color: #fff;
  }
  .wrapRowOnlineAgent1 {
    margin: 70px 0px 120px 0px;
  }
  .wrapRowOnlineAgent2 {
    margin: 120px 0px 80px 0px;
  }
  .wrapRowOnlineAgent3 {
    margin: 80px 0px 200px 0px;
  }
  .wrapRowOnlineAgent4 {
    margin: 200px 0px 160px 0px;
  }
  .wrapRowOnlineAgent5 {
    margin: 160px 0px 180px 0px;
  }
  .wrapRowOnlineAgent6 {
    margin: 180px 0px 150px 0px;
  }
  .wrapRowOnlineAgent7 {
    margin: 150px 0px 80px 0px;
  }
  .wrapRowOnlineAgent8 {
    margin: 80px 0px 180px 0px;
  }
  .wrapRowOnlineAgent9 {
    margin: 180px 0px 150px 0px;
  }
  .wrapRowOnlineAgent10 {
    margin: 150px 0px 160px 0px;
  }
  .h2OnlineAgent {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    text-transform: uppercase;
    color: #06076a;
  }
  .pOnlineAgent {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #858591;
  }
  .imgOnlineAgent2 {
    width: 100%;
    margin: 28px auto 0px auto;
    display: block;
  }
  .imgOnlineAgent3 {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .imgOnlineAgent4 {
    width: 53%;
    filter: drop-shadow(0px 0px 24px rgba(122, 121, 156, 0.06)),
      drop-shadow(10px 10px 100px rgba(229, 229, 229, 0.5));
    border-radius: 3px;
    display: block;
    margin: 0px auto;
  }
  .wrapRowOnlineAgent5 p {
    margin: 50px 0px 0px 0px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 2500px) {
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .h2OnlineAgent{
    text-align: center;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .imgOnlineAgent4 {
    width: 42%;
    margin: 50px auto 0px auto;
  }
  .imgOnlineAgent45{
    width: 62%;
    display: block;
    margin: 50px auto 0px auto;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
}
